// ---------------------------------------------------------
// @Background Colours
// ---------------------------------------------------------

.bg-danger {
  background-color: $default-danger !important;
}

.bg-info {
  background-color: $default-info !important;
}

.bg-success {
  background-color: $default-success !important;
}

.bg-warning {
  background-color: $default-warning !important;
}

.bg-primary {
  background-color: $default-primary !important;
}

.bg-white {
  background-color: $default-white !important;
}

.bg-required {
  background-color: $required-color !important;
}

.bg-tab-grey {
  background-color: $tab-grey !important;
}

.bg-tinted-cyan {
  background-color: $tinted-cyan !important;
}

.bg-tinted-yellow {
  background-color: $tinted-yellow !important;
}

.bg-tinted-green {
  background-color: $tinted-green !important;
}

.bg-tinted-red {
  background-color: $tinted-red !important;
}

.bg-tinted-grey {
  background-color: $tinted-grey !important;
}

.bg-inverse-success {
  background-color: $inverse-success !important;
}

.bg-inverse-danger {
  background-color: $inverse-danger !important;
}

.bg-grey-100 {
  background-color: $grey-100 !important;
}

.bg-grey-300 {
  background-color: $grey-300 !important;
}

.bg-grey-400 {
  background-color: $grey-400 !important;
}

// ---------------------------------------------------------
// @Border Colours
// ---------------------------------------------------------

.border-antd {
  border-color: $ant-border-color !important;
}

.border-primary {
  border-color: $default-primary !important;
}

.border-danger {
  border-color: $default-danger !important;
}

.border-required {
  border-color: $required-color !important;
}

.border-info {
  border-color: $default-info !important;
}

.border-success {
  border-color: $default-success !important;
}

.border-warning {
  border-color: $default-warning !important;
}

.border-transparent {
  border-color: transparent !important;
}

// ---------------------------------------------------------
// @Badge Colours
// ---------------------------------------------------------

.badge-todo {
  background-color: $badge-todo !important;
  border-color: $badge-todo !important;
}

.badge-progress {
  background-color: $badge-progress !important;
  border-color: $badge-progress !important;
}

.badge-resolved {
  background-color: $badge-resolved !important;
  border-color: $badge-resolved !important;
}

.badge-problem {
  background-color: $badge-problem !important;
  border-color: $badge-problem !important;
}

.badge-closed {
  background-color: $badge-closed !important;
  border-color: $badge-closed !important;
}

// ---------------------------------------------------------
// @Tag Colours
// ---------------------------------------------------------

.tag-todo {
  background-color: $badge-todo !important;
  border-color: $badge-todo !important;
  color: #FFF !important;
}

.tag-progress {
  background-color: $badge-progress !important;
  border-color: $badge-progress !important;
  color: #FFF !important;
}

.tag-resolved {
  background-color: $badge-resolved !important;
  border-color: $badge-resolved !important;
  color: #FFF !important;
}

.tag-problem {
  background-color: $badge-problem !important;
  border-color: $badge-problem !important;
  color: #FFF !important;
}

.tag-closed {
  background-color: $badge-closed !important;
  border-color: $badge-closed !important;
  color: #FFF !important;
}

// ---------------------------------------------------------
// @Text Colours
// ---------------------------------------------------------

.ant-text-danger {
  color: $ant-danger !important;
}

.text-inherit {
  color: inherit !important;
}

.text-danger {
  color: $default-danger !important;
}

.default-grey {
  color: $default-grey !important;
}

.yellow {
  color: rgb(255, 171, 0) !important;
}

.text-inverse-danger {
  color: $inverse-danger !important;
}

.text-required {
  color: $required-color !important;
}

.text-info {
  color: $default-info !important;
}

.text-success {
  color: $default-success !important;
}

.text-white {
  color: $default-white !important;
}

.text-ant-default {
  color: $ant-text-color !important;
}

.text-ant-disabled {
  color: $ant-disabled-color !important;
}

.text-ant-blue {
  color: $ant-blue !important;
}

.text-ant-grey {
  color: $ant-grey-color !important;
}

.text-warning {
  color: $default-warning !important;
}

.color-grey-400 {
  color: $grey-400 !important;
}

.color-grey-500 {
  color: $grey-500 !important;
}

.color-grey-600 {
  color: $grey-600 !important;
}

.color-grey-700 {
  color: $grey-700 !important;
}

.color-grey-800 {
  color: $grey-800 !important;
}

.primaryColor {
  color: $default-primary !important;
}

.defaultColor {
  color: $default-text-color !important;
}

// ---------------------------------------------------------
// @Fill Colours
// ---------------------------------------------------------

.fill-danger {
  fill: $default-danger !important;
}

.fill-warning {
  fill: $default-warning !important;
}
