@import 'assets/styles/index.scss';

.KpiPriority-Field-Select {
  display: inline-block;
  padding-right: 5px;
}

@include to($breakpoint-sm) {
  .KpiPriority-Field-Select {
    width: 100%;
    padding-right: 0;
    margin-bottom: 10px;
  }
}