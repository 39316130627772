.ant-table-tbody > tr.error > td {
  background-color: $tinted-red !important;
}

.ant-table-tbody > tr.ant-table-row:hover.error > td {
  background: $tinted-red !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: $tab-grey !important;
}

.ant-input-number input {
  text-align: right !important;
}

.ant-input-number-handler-wrap {
  display: none;
}

.ant-table-small .ant-table-thead > tr > th {
  background-color: #FFF;
}

// overridden text color for input and textarea components when they are in disabled state
.ant-input-disabled {
  color: rgba(0, 0, 0, 0.50) !important;
}

// overridden styles of select component when it is in disabled state
.ant-select-disabled span.ant-select-selection-item {
  color: rgba(0, 0, 0, 0.50) !important;
}

.ant-select-disabled.ant-select-dropdown {
  background-color: #f5f5f5 !important;
  color: rgba(0, 0, 0, 0.50) !important;
}

.ant-select-disabled.ant-select-dropdown .ant-select-item-option {
  background-color: inherit !important;
  color: inherit !important;
  cursor: not-allowed !important;
}

.ant-tooltip .ant-tooltip-inner {
  white-space: pre-line;
}

.ant-steps-finish-icon {
  color: $default-success !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: $default-success !important;
}